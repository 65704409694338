<template>
  <v-container fluid>
    <ShopTabs/>
    <v-row>
      <v-col cols="9" >
        <div class="d-flex justify-space-between align-center gap-x-2 mb-3">
          <h3 class="font-semibold text-blue">
            Select Service
          </h3>
        </div>
        <div class="d-flex align-center gap-x-4 overflow-x-auto pb-3 mb-2">
          <v-btn
              v-for="(service) in venueServices" :key="`${service.name}`"
              :class="{'active':selectedService && selectedService.name.toLowerCase()===service.name.toLowerCase()}"
              class="service"
              elevation="0"
              @click="changeService(service.id)"
          >
            {{ service.name }}
          </v-btn>
        </div>
        <div v-if="selectedService">
          <v-form ref="form" class="w-full" v-model="valid">
            <v-card class="shadow-0 bordered pa-0 rounded-3 mb-5">
              <div class="d-flex justify-space-between align-center gap-x-2 pb-1 mb-3 border-bottom rounded-0 px-5 pt-5">
                <h3 class="font-semibold text-blue text-base">
                  Booking Details
                </h3>
                <div v-if="bookingForm.facility && bookingForm.facility.per_capacity === 0">
                  <div class="d-flex align-center gap-x-2">
                    <p class="text-sm mb-0">
                      Repeat Booking
                    </p>
                    <v-switch
                        v-model="bookingForm.repeat"
                        id="repeat_booking"
                        hide-details
                        @change="changeRepeatBookingSwitch"
                        class="ma-0 v-input--reverse"
                        dense
                    />
                  </div>
                </div>
              </div>
              <v-row class="px-5 my-3" v-if="!bookingForm.repeat">
                <v-col lg="4" sm="12">
                  <label for="">
                    Booking Date
                  </label>
                  <date-field
                      v-model="bookingForm.date"
                      :backFill="checkBackfillPermission($modules.facility.schedule.slug)"
                      :hide-details="true"
                      @change="loadTimings"
                      :rules="[(v) => !!v || 'Date is required']"
                      :dense="true"
                      label=""
                  >
                    <template #prepend-inner>
                      <CalendarIcon/>
                    </template>
                  </date-field>
                </v-col>
                <v-col lg="2" sm="12">
                  <label for="">
                    {{ endTimeDisable ? 'Arrival' : 'Start' }} Time
                  </label>
                  <v-select
                      v-model="bookingForm.start_time"
                      :items="bookingForm.start_timings"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :rules="[(v) => !!v || 'Start Time Required']"
                      background-color="#fff"
                      @change="changeStartTime(),changeEndTime(),loadFacilities()"
                      dense
                      hide-details="auto"
                      item-text="text"
                      item-value="value"
                      outlined
                  >
                    <template #prepend-inner>
                      <TimeIcon/>
                    </template>
                  </v-select>
                </v-col>
                <v-col lg="2" sm="12">
                  <label for="">
                    {{ endTimeDisable ? 'Departure' : 'End' }} Time
                  </label>
                  <v-select
                      :readonly="endTimeDisable"
                      v-model="bookingForm.end_time"
                      :items="bookingForm.end_timings"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :rules="[(v) => !!v || 'End Time Required']"
                      background-color="#fff"
                      @change="loadData"
                      dense
                      hide-details="auto"
                      item-text="text"
                      item-value="value"
                      outlined
                  >
                    <template #prepend-inner>
                      <TimeIcon/>
                    </template>
                    <template v-slot:item="{ item }">
                      <span v-html="item.text"></span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span v-html="item.text"></span>
                    </template>
                  </v-select>
                </v-col>
                <v-col lg="4" sm="12">
                  <label for="">
                    Select Facility
                  </label>
                  <v-select
                      v-model="bookingForm.facility"
                      :items="bookingForm.facilities"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :rules="[(v) => !!v || 'Facility is Required']"
                      background-color="#fff"
                      dense
                      hide-details="auto"
                      item-text="name"
                      outlined
                      @change="getRentals"
                      return-object
                  />
                </v-col>
              </v-row>
              <v-row class="px-5 my-3" v-if="bookingForm.repeat">
                  <v-col
                    md="12"
                    v-for="(repeat, index) in $store.getters.getRepeats"
                    :key="`repeat_${index}`"
                  >
                    <repeat-booking
                      @repeat="setRepeatData"
                      @remove="removeRepeatRow"
                      :date="repeat.date"
                      :facilityId="bookingForm.facility.id"
                      :venueServiceId="selectedService.id"
                      :index="index"
                      :minBookingTime="min_booking_time"
                      :enableOvernightBooking="enableOvernightBooking"
                      :facilities="bookingForm.facilities"
                    ></repeat-booking>
                  </v-col>
              </v-row>
              <div v-if="bookingForm.repeat && $store.getters.getRepeats.length < 5" class="d-flex justify-center align-center mt-3">
                <v-btn class="white--text " icon text @click="addRepeatRow">
                  <PlusIcon/> 
                </v-btn>
              </div>
              
              <div class="product-details font-medium">
                <ProductSelection
                  :key="refreshComponent"
                  :products="bookingForm.products"
                  :categories="categories"
                  :categoriesList="categoriesList"
                  :taxTypes="taxTypes"
                  :bookingForm="bookingForm"
                  :productCatId="bookingForm.productCategoryId"
                  :venueServiceId="selectedService.id"
                  :productCombinations="productCombinations"
                  :deletedProducts="deletedProducts"
                  :currentOrderProducts="currentOrderProducts"
                  :repeatId="repeatId"
                  @chooseRentalCombination="chooseRentalCombination"
                  @deleteProduct="deleteProduct"
                  @setCurrentOrderProducts="setCurrentOrderProducts"
                  @removeProduct="(productIndex) => removeProduct(productIndex)"
                  @setCustomerProduct=" (data) => setCustomerProduct(null, data)"
              />
              </div>
              <ShopCustomerPopup
                  v-if="showCustomerPopup"
                  bookingType="facility"
                  :promotions="promotions"
                  :show-customer-popup="showCustomerPopup"
                  @close="closeCustomerPopup"
                  @setCustomers="setCustomers"
                  :max-customers="getMaxCustomers()"
                  :bookingData="bookingForm"
              />
            </v-card>
            <div class="d-flex justify-end align-center mt-8 gap-x-4">
              <p class="mb-0 font-semibold">
                Total: {{ this.bookingForm.total_price | toCurrency }}
              </p>
              <v-btn class="white--text blue-color" height="36" text @click="validateBooking">
                Add to Cart
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
    <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="closeOfferPopup" />
  </v-container>
</template>

<script>

import ShopTabs from "@/views/Shop/ShopTabs.vue";
import moment,{ now } from "moment/moment";
import TimeIcon from "@/assets/images/retail/time.svg";
import CalendarIcon from "@/assets/images/retail/calendar.svg";
import PlusIcon from "@/assets/images/misc/plus.svg";
import ShopCustomerPopup from "@/components/Shop/ShopCustomerPopup.vue";
import RepeatBooking from "@/components/Schedule/Facility/RepeatBooking";
import ProductSelection from "@/components/Shop/Facility/ProductSelection";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";
import { EventBus } from '@/main.js';
export default {
  components: {
    PackageCardPopup,
    ShopCustomerPopup,
    ShopTabs, TimeIcon, CalendarIcon, PlusIcon,RepeatBooking,ProductSelection
  },
  data() {
    return {
      enableOvernightBooking:false,
      valid: false,
      showCustomerPopup: false,
      time_increment: 60,
      min_booking_time: 60,
      fs_start_time: null,
      fs_end_time: null,
      facilities: [],
      selectedService: null,
      bookingForm: {
        facilities: [],
        date: moment().format("YYYY-MM-DD"),
        start_time: null,
        end_time: null,
        facility: null,
        repeatType: 'Weekly',
        weekdays: [],
        products: [],
        repeatEndType: 'Count',
        endAfter: 1,
        productCategoryId: null,
        selectedProduct: {
          title: '',
          quantity: 1,
          tax_type_id: null,
          pre_tax_price: 0,
          total_price: 0,
          rental: true,
        },
        start_timings: [],
        end_timings: [],
        timings: [],
        repeat: false,
        attendance: false,
        attendance_count: 1,
        opt_marketing: false,
        price: 0,
        discount: null,
        promotion_code: null,
        total_price: 0,
      },
      repeatId: null,
      currentRepeatDates: null,
      deletedProducts: [],
      productCombinations: [],
      showCombinationDialog: false,
      selectedCombinationPrice: 0,
      currentOrderProducts: [],
      categories: [],
      categoriesList: [
        {name: "All", id: null},
        {name: "Open Product", id: -1},
      ],
      refreshComponent:0,
      fullDay: false,
      bookingFormTotal: 0,
      bookingFormAdded: 1,
      totalParticipants: 0,
      bookedCapacity: 1,
      attendanceSwitch: false,
      isEmiratesIdCheck:false,
      offers: [],
      showOfferPopup: false,
      fid: null,
    }
  },
  mounted() {
    if (!this.$store.getters.getTaxTypes.status) {
      this.$store.dispatch("loadTaxTypes");
    }
    this.getStartTimes();

    EventBus.$on("facility-query-event", this.handleFacilityQueryEvent);
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    EventBus.$off("facility-query-event",  this.handleFacilityQueryEvent);
  },
  watch: {
    reloadShopModule(val){
      if(val && val === 'facility'){
        this.resetAll();
      }
    }
  },
  computed: {
    reloadShopModule(){
      return this.$store.getters.getReloadShopModule;
    },
    getCurrentVenueId(){
      return this.$store.getters.svid?this.$store.getters.svid:this.$store.getters.userInfo.venue_id;
    },
    endTimeDisable() {
      // return !!(this.selectedService && this.selectedService.per_capacity === 1);
      if(this.bookingForm && this.bookingForm.facility && this.bookingForm.facility.per_capacity){
        return true;
      }
      return false;
    },
    venueServices() {
      const services = this.$store.getters.getShopVenueServices.data.filter(service => service.name !== "POS");
      const uniqueServices = [];

      const serviceNames = new Set();
      for (const service of services) {
        if (!serviceNames.has(service.name)) {
          serviceNames.add(service.name);
          uniqueServices.push(service);
        }
      }

      return uniqueServices;
      // return this.$store.getters.getSportsService.filter((service) => service.name != "POS");
    },
    weekdays() {
      return [
        {name: 'Monday', value: 1},
        {name: 'Tuesday', value: 2},
        {name: 'Wednesday', value: 3},
        {name: 'Thursday', value: 4},
        {name: 'Friday', value: 5},
        {name: 'Saturday', value: 6},
        {name: 'Sunday', value: 7},
      ]
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data
    },
    promotions() {
      return this.$store.getters.getShopPromotions.data;
    },
  },
  methods: {
    checkEnableOvernightBooking(){
      if(this.venueServiceConfiguration.enable_over_night_booking){
        this.enableOvernightBooking = true;
      }
    },
    handleFacilityQueryEvent(data) {
      console.log('bus:', data);
      this.getQueryParams();
    },
    getQueryParams() {
      const query = this.$route.query;
      // Validate the 'vid' (Venue ID) parameter
      if (query.vid && !isNaN(query.vid) && query.vsid && !isNaN(query.vsid) && query.fid && !isNaN(query.fid)) {
        this.fid = parseInt(query.fid);
        this.changeService(parseInt(query.vsid));
      }
      this.$forceUpdate();
    },
    async setCustomers(data) {
      let cartData = {products: [], date_ranges: []};
      cartData.venueId = this.getCurrentVenueId;
      cartData.type = "facility";
      cartData.facility_id = this.bookingForm.facility.id;
      cartData.facility_name = this.bookingForm.facility.name;
      cartData.venue_service_id = this.selectedService.id;
      cartData.date = this.bookingForm.date;
      cartData.start_date = this.bookingForm.date;
      cartData.start_time = this.bookingForm.start_time;
      cartData.end_time = this.bookingForm.end_time;
      cartData.discount = this.bookingForm.discount?this.bookingForm.discount:null;
      if(this.bookingForm.promotion_code){
        cartData.promotion_code = this.bookingForm.promotion_code;
      }
      if(this.bookingForm.repeat){
        cartData.repeat = true;
      }

      data.customers.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      cartData.customers = data.customers;
      
      
      let tickets = this.bookingForm.products;
      tickets = tickets.map((ele) => {
        if (ele.quantity > 0) {
            if(ele.discount){
              if(ele.product_price && ele.price >= ele.product_price){
                ele.price = (ele.price/ele.quantity);
                ele.tax = (ele.tax/ele.quantity);
              }
              ele.total_price = ele.price + (ele.tax?ele.tax:ele.tax_amount);
            }else{
              if(ele.product_price && ele.price > ele.product_price){
                ele.price = (ele.price/ele.quantity);
                if(ele.tax && ele.tax_amount && ele.tax > ele.tax_amount){
                  ele.tax = (ele.tax/ele.quantity);
                }
                ele.total_price = ele.price + (ele.tax_amount?ele.tax_amount:ele.tax);
                // ele.total_price = ele.price + (ele.tax?ele.tax:ele.tax_amount);
              }
            }
        }else {
            ele.total_price = 0;
        }
        return ele;
      });
      let pushObject = tickets.filter(ele => ele.quantity > 0)

      cartData.products = [...cartData.products, ...pushObject];

      await this.$store.dispatch("addToCart", JSON.parse(JSON.stringify(cartData))).then( () => {
        this.checkProductOffers();
      });

      setTimeout(() => {
        this.showCustomerPopup = false;
        this.$emit('close');
        this.resetBookingForm();
        this.loadFacilities();
        this.loadTimings();
      }, 250)

    },
    setCustomerData(data, index = null) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (index === null) {
        if (data.mobile && data.first_name && data.customer_id) {
          this.isEmiratesIdCheck = false;
          this.bookingForm.promotion_code = null;
          this.searchMember(
            data.mobile,
            data.customer_id,
            data.first_name,
            data.last_name
          );
        } else {
          this.clearCardAndBenefits();
        }
        if (!data.customer_id && this.id > 0) {
          this.$set(this.bookingForm, "customer_id", null);
        }

        if (!data.name && data.first_name) {
          this.$set(this.bookingForm, "name", data.first_name);
        }
        if (
          this.bookingForm.customer_id &&
          !data.customer_id &&
          this.bookingForm.name != data.name &&
          this.bookingForm.mobile != data.mobile
        ) {
          this.$set(this.bookingForm, "mobile", null);
          this.bookingForm.search = null;
          this.bookingForm.nameSearch = null;
          this.$set(this.bookingForm, "email", null);
          this.$set(this.bookingForm, "gender", null);
          this.$set(this.bookingForm, "name", null);
          this.$set(this.bookingForm, "customer_id", null);
          this.$set(this.bookingForm, "first_name", null);
          this.$set(this.bookingForm, "image_path", null);
          this.$set(this.bookingForm, "dob", null);
          this.$set(this.bookingForm, "age_group", null);
          this.$set(this.bookingForm, "country_id", null);
          this.$set(this.bookingForm, "last_name", null);
          this.$set(this.bookingForm, "opt_marketing", false);
          this.$set(this.bookingForm, "id_proof_type_id", null);
          this.$set(this.bookingForm, "id_proof_number", null);
          this.$set(this.bookingForm, "id_proof_path", null);
          this.$set(this.bookingForm, "customer_tag", null);
          this.$forceUpdate();
        }
        if (data.mobile) this.$set(this.bookingForm, "mobile", data.mobile);
        if (data.email) this.$set(this.bookingForm, "email", data.email);
        if (data.country_id) {
          this.$set(this.bookingForm, "country_id", data.country_id);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "country_id", null);
          }
        }
        if (data.gender) {
          this.$set(this.bookingForm, "gender", data.gender);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "gender", null);
          }
        }
        if (data.dob) {
          this.$set(this.bookingForm, "dob", data.dob);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "dob", null);
          }
        }
        if (data.age_group) {
          this.$set(this.bookingForm, "age_group", data.age_group);
        }
        else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "age_group", null);
          }
        }
        if (data.name) {
          data.name = data.name.replace(/\s\s+/g, " ");
          data.name = data.name.trim();
          this.$set(this.bookingForm, "name", data.name);
        }
        if (data.last_name) {
          data.last_name = data.last_name.replace(/\s\s+/g, " ");
          data.last_name = data.last_name.trim();
          this.$set(this.bookingForm, "last_name", data.last_name);
        } else {
          this.$set(this.bookingForm, "last_name", null);
        }
        if (data.first_name) {
          data.first_name = data.first_name.replace(/\s\s+/g, " ");
          data.first_name = data.first_name.trim();
          this.$set(this.bookingForm, "first_name", data.first_name);
        }
        if (data.customer_id)
          this.$set(this.bookingForm, "customer_id", data.customer_id);
        if (data.image_path) {
          this.$set(this.bookingForm, "image_path", data.image_path);
        } else {
          this.$set(this.bookingForm, "image_path", null);
        }
        if (data.id_proof_type_id) {
          this.$set(
            this.bookingForm,
            "id_proof_type_id",
            data.id_proof_type_id
          );
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof_type_id", null);
          }
        }
        if (data.id_proof_number) {
          this.$set(this.bookingForm, "id_proof_number", data.id_proof_number);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof_number", null);
          }
        }
        if (data.id_proof_path) {
          this.$set(this.bookingForm, "id_proof_path", data.id_proof_path);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof_path", null);
          }
        }
        if (data.customer_tag) {
          this.$set(this.bookingForm, "customer_tag", data.customer_tag);
        } else {
          this.$set(this.bookingForm, "customer_tag", null);
        }

        if (data.id_proof) {
          this.$set(this.bookingForm, "id_proof", data.id_proof);
        } else {
          if (!this.isEmiratesIdCheck && data.customer_id) {
            this.$set(this.bookingForm, "id_proof", null);
          }
        }
        if (data.opt_marketing) {
          if (data.opt_marketing == 1) {
            this.$set(this.bookingForm, "opt_marketing", true);
          } else {
            this.$set(this.bookingForm, "opt_marketing", false);
          }
        }
        if (data.customer_documents) {
          this.bookingForm.customer_documents = data.customer_documents;
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
          ) {
            this.$set(
              this.bookingForm,
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
          ) {
            this.$set(
              this.bookingForm,
              "id_proof_number",
              data.customer_documents[0].id_proof_number
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
          ) {
            this.$set(
              this.bookingForm,
              "id_proof_path",
              data.customer_documents[0].id_proof_path
            );
          }
        } else {
          if (data.customer_id) {
            this.bookingForm.customer_documents = [];
          }
        }
      } else {
        if (data.mobile && data.first_name && data.customer_id) {
          this.isEmiratesIdCheck = false;
          this.searchMember(
            data.mobile,
            data.customer_id,
            data.first_name,
            data.last_name,
            index
          );
        } else {
          this.clearCardAndBenefits(index);
        }

        if (!data.customer_id) {
          this.$set(this.attendanceCustomers[index], "customer_id", null);
        }

        if (!data.name && data.first_name) {
          this.$set(this.attendanceCustomers[index], "name", data.first_name);
        }
        if (
          this.attendanceCustomers[index].customer_id &&
          !data.customer_id &&
          this.attendanceCustomers[index].name != data.name &&
          this.attendanceCustomers[index].mobile != data.mobile
        ) {
          this.$set(this.attendanceCustomers[index], "mobile", null);
          this.attendanceCustomers[index].search = null;
          this.attendanceCustomers[index].nameSearch = null;
          this.$set(this.attendanceCustomers[index], "email", null);
          this.$set(this.attendanceCustomers[index], "gender", null);
          this.$set(this.attendanceCustomers[index], "name", null);
          this.$set(this.attendanceCustomers[index], "customer_id", null);
          this.$set(this.attendanceCustomers[index], "first_name", null);
          this.$set(this.attendanceCustomers[index], "image_path", null);
          this.$set(this.attendanceCustomers[index], "dob", null);
          this.$set(this.attendanceCustomers[index], "age_group", null);
          this.$set(this.attendanceCustomers[index], "country_id", null);
          this.$set(this.attendanceCustomers[index], "last_name", null);
          this.$set(this.attendanceCustomers[index], "opt_marketing", false);
          this.$set(this.attendanceCustomers[index], "id_proof_type_id", null);
          this.$set(this.attendanceCustomers[index], "id_proof_number", null);
          this.$set(this.attendanceCustomers[index], "id_proof_path", null);
          this.$forceUpdate();
        }
        if (data.mobile)
          this.$set(this.attendanceCustomers[index], "mobile", data.mobile);
        if (data.email)
          this.$set(this.attendanceCustomers[index], "email", data.email);
        if (data.country_id) {
          this.$set(
            this.attendanceCustomers[index],
            "country_id",
            data.country_id
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "country_id", null);
          }
        }
        if (data.gender) {
          this.$set(this.attendanceCustomers[index], "gender", data.gender);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "gender", null);
          }
        }
        if (data.dob) {
          this.$set(this.attendanceCustomers[index], "dob", data.dob);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "dob", null);
          }
        }
        if (data.age_group) {
          this.$set(this.attendanceCustomers[index], "age_group", data.age_group);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "age_group", null);
          }
        }
        if (data.name) {
          data.name = data.name.replace(/\s\s+/g, " ");
          data.name = data.name.trim();
          this.$set(this.attendanceCustomers[index], "name", data.name);
        }
        if (data.last_name) {
          data.last_name = data.last_name.replace(/\s\s+/g, " ");
          data.last_name = data.last_name.trim();
          this.$set(
            this.attendanceCustomers[index],
            "last_name",
            data.last_name
          );
        } else {
          this.$set(this.attendanceCustomers[index], "last_name", null);
        }
        if (data.first_name) {
          data.first_name = data.first_name.replace(/\s\s+/g, " ");
          data.first_name = data.first_name.trim();
          this.$set(
            this.attendanceCustomers[index],
            "first_name",
            data.first_name
          );
        }
        if (data.customer_id)
          this.$set(
            this.attendanceCustomers[index],
            "customer_id",
            data.customer_id
          );
        if (data.image_path) {
          this.$set(
            this.attendanceCustomers[index],
            "image_path",
            data.image_path
          );
        } else {
          this.$set(this.attendanceCustomers[index], "image_path", null);
        }
        if (data.id_proof_type_id) {
          this.$set(
            this.attendanceCustomers[index],
            "id_proof_type_id",
            data.id_proof_type_id
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_type_id",
              null
            );
          }
        }
        if (data.id_proof_number) {
          this.$set(
            this.attendanceCustomers[index],
            "id_proof_number",
            data.id_proof_number
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "id_proof_number", null);
          }
        }
        if (data.id_proof_path) {
          this.$set(
            this.attendanceCustomers[index],
            "id_proof_path",
            data.id_proof_path
          );
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "id_proof_path", null);
          }
        }

        if (data.id_proof) {
          this.$set(this.attendanceCustomers[index], "id_proof", data.id_proof);
        } else {
          if (!this.isEmiratesIdCheck) {
            this.$set(this.attendanceCustomers[index], "id_proof", null);
          }
        }
        if (data.opt_marketing) {
          if (data.opt_marketing == 1) {
            this.$set(this.attendanceCustomers[index], "opt_marketing", true);
          } else {
            this.$set(this.attendanceCustomers[index], "opt_marketing", false);
          }
        }
        if (data.customer_documents) {
          this.attendanceCustomers[index].customer_documents =
            data.customer_documents;
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
          ) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
          ) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_number",
              data.customer_documents[0].id_proof_number
            );
          }
          if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
          ) {
            this.$set(
              this.attendanceCustomers[index],
              "id_proof_path",
              data.customer_documents[0].id_proof_path
            );
          }
        } else {
          this.attendanceCustomers[index].customer_documents = [];
        }
        if (data.customer_tag) {
          this.$set(
            this.attendanceCustomers[index],
            "customer_tag",
            data.customer_tag
          );
        } else {
          this.$set(this.attendanceCustomers[index], "customer_tag", null);
        }
      }
      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.$forceUpdate();
    },
    getMaxCustomers() {
      let max = 1;
      if(this.bookingForm.facility && this.bookingForm.facility.per_capacity == 1){
        max = this.bookingForm.products.reduce((sum, product) => {
          return sum + product.participant_count;
        }, 1);
      }
      return max;
    },
    closeCustomerPopup() {
      this.showCustomerPopup = false;
    },
    resetBookingForm() {
      this.bookingForm = {
        facilities: [],
        date: moment().format("YYYY-MM-DD"),
        start_time: null,
        end_time: null,
        facility: null,
        repeatType: 'Weekly',
        weekdays: [],
        products: [],
        repeatEndType: 'Count',
        endAfter: 1,
        productCategoryId: null,
        selectedProduct: {
          title: '',
          quantity: 1,
          tax_type_id: null,
          pre_tax_price: 0,
          total_price: 0,
          rental: true,
        },
        start_timings: [],
        end_timings: [],
        timings: [],
        repeat: false,
        attendance: false,
        attendance_count: 1,
        opt_marketing: false,
        price: 0,
        discount: null,
        promotion_code: null,
        total_price: 0,
      }
      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    changeService(serviceId) {
      this.selectedService = this.venueServices.find(service => service.id === parseInt(serviceId))
      if(this.selectedService) {
        this.resetBookingForm();
        this.resetProducts();
        // this.loadFacilities();
        this.loadTimings();
        this.loadFacilityPromotions();
      }
    },
    resetProducts(){
      this.bookingForm.products = [];
      this.currentOrderProducts = [];
      this.productCombinations = [];
      this.categories = [];
      this.categoriesList = [
        {name: "All", id: null},
        {name: "Open Product", id: -1},
      ];
      this.fullDay = false;
      this.enableOvernightBooking = false;
      this.refreshComponent = now();
      this.$forceUpdate();
    },
    getTimings(facilities) {

      let minStartTime = moment("23:59:59", "HH:mm:ss");
      let maxEndTime = moment("00:00:00", "HH:mm:ss");

      facilities.forEach(facility => {
        facility.facility_rentals.forEach(rental => {
          const startTime = moment(rental.start_time, "HH:mm:ss");
          const endTime = moment(rental.end_time, "HH:mm:ss");

          if (startTime.isBefore(minStartTime)) {
            minStartTime = startTime;
          }

          if (endTime.isAfter(maxEndTime)) {
            maxEndTime = endTime;
          }
        });
      });

      return {
        start_time: minStartTime.format("HH:mm:ss"),
        end_time: maxEndTime.format("HH:mm:ss")
      };

    },
    generateTimeSlotsAdditional(minStartTime, increment, minBookingTime, bookingStartTime) {

      const timeSlots = [];
      let currentTime = moment(minStartTime, 'HH:mm:ss');
      const bookingEndTime = moment(bookingStartTime, 'HH:mm:ss').subtract(minBookingTime - increment, 'minutes');
      let key = 0;
      while (currentTime.isSameOrBefore(bookingEndTime)) {
        if (key !== 0) {
          timeSlots.push({
            text: currentTime.format('hh:mm a') + '<span style="color: red"> +1</span>',
            value: currentTime.format('HH:mm:ss'),
          });
        } else {
          key++;
        }
        currentTime.add(increment, 'minutes');
      }
      return timeSlots;
    },
    generateTimeSlot(startTime, endTime, increment) {
      const timeSlots = [];
      let currentTime = moment(startTime, 'HH:mm:ss');
      let currentSystemTime = moment();
      const currentSystemDate = moment().format('YYYY-MM-DD');
      const endMoment = moment(endTime, 'HH:mm:ss');

      if (endTime === '23:59:59') {
        endMoment.add(1, 'second');
      }

      while (currentTime.isBefore(endMoment) || currentTime.isSame(endMoment)) {
        if (currentTime.isSame(endMoment) && currentTime.format('HH:mm:ss') === "00:00:00") {
          timeSlots.push('23:59:59');
        } else {
          if(this.bookingForm.date === currentSystemDate){
            if(currentTime.isSameOrAfter(currentSystemTime) && this.bookingForm.date == currentSystemDate){ 
              timeSlots.push(currentTime.format('HH:mm:ss'));
            }
          }else{
            timeSlots.push(currentTime.format('HH:mm:ss'));
          }
         
        }
        currentTime.add(increment, 'minutes');
      }

      return timeSlots;
    },
    convertToTimeSlotObjects(timeSlots) {
      return timeSlots.map(time => {
        return {
          text: moment(time, 'HH:mm:ss').format('hh:mm a'),
          value: time
        };
      });
    },
    validateBooking(){
      let data = JSON.parse(JSON.stringify(this.bookingForm));
      if(this.bookingForm.repeat){
        const repeats = this.$store.getters.getRepeats;
        if(repeats.length == 0){
          this.showError("Fill all repeat booking fields");
          return;
        }
        let isRepeatValidated = true;
        repeats.forEach( (repeat) => {
          if(repeat.date === null || repeat.start_time === null || repeat.end_time === null || repeat.facility_id === null || repeat.repeatType === null || repeat.repeatNumber === null || repeat.repeatEndType === null ){
            isRepeatValidated = false;
          }
          if(repeat.repeated_dates && repeat.repeated_dates.length === 0){
            isRepeatValidated = false;
          }
        })
        
        if(!isRepeatValidated){
          this.showError("Fill all repeat booking fields");
          return;
        }
        /** check rental and open products attached */
        let openRentalProductCount = data.products.filter( (p) => p.rental === true && p.product_id === 0).length;
        let rentalProductCount = data.products.filter( (p) => p.rental === true && p.product_id !== 0).length;

        if(openRentalProductCount > 0 && rentalProductCount > 0){
          this.showError("Cannot select open product and normal product at the same time.");
          return;
        }
        if(this.isFacilityTimingAlreadyInCart()){
          this.showError("Slot already in cart");
          return;
        }
        this.showCustomerPopup = true;
        return;
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields to continue");
        return;
      }
      if(this.isFacilityTimingAlreadyInCart()){
        this.showError("Slot already in cart");
        return;
      }
      this.showLoader('Validating Booking');
      delete data.facilities;
      delete data.end_timings;
      delete data.start_timings;
      delete data.selectedProduct;
    
      this.$http.post(`venues/shop/facilities/validate-booking/${this.selectedService.id}`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showCustomerPopup = true;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
            this.hideLoader();
          });
    },
    removeProduct(pIndex, index = null) {
      if (index === null) {
        let data = this.bookingForm.products[pIndex];
        if (this.bookingForm.products[pIndex].inventory_enable) {
          if (data.order_item_id) {
            this.deletedProducts.push(data.order_item_id);
          }
          let products = this.categories.find(
            (item) => item.id == data.category_id
          ).products;
          if (products) {
            products.forEach((el) => {
              if (el.id == data.product_id) {
                el.sales -= data.quantity;
              }
            });
          }
        }
        this.bookingForm.products.splice(pIndex, 1);
        if (this.bookingForm.repeats && this.bookingForm.repeats.length > 0) {
          this.bookingForm.repeats.forEach((repeat) => {
            let findIndex = repeat.products.findIndex(
              (x) => x.id == data.product_id
            );
            if (findIndex != -1) {
              repeat.products.splice(pIndex, 1);
            }
          });
        }

        if (data.rental == false && this.repeatId) {
          let findIndex = this.currentOrderProducts.findIndex(
            (x) => x.product_id == data.product_id
          );
          if (findIndex != null) {
            this.currentOrderProducts.splice(findIndex, 1);
          }
        }
        this.$forceUpdate();
        this.bookingForm.total_price = this.bookingForm.products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
        this.refreshComponent++;
        this.updateBookingFormQuantity();
        this.$forceUpdate();
      } else {
        let data = this.attendanceCustomers[index].products[pIndex];
        if (this.attendanceCustomers[index].products[pIndex].inventory_enable) {
          if (data.order_item_id) {
            this.deletedProducts.push(data.order_item_id);
          }
          let products = this.categories.find(
            (item) => item.id == data.category_id
          ).products;
          if (products) {
            products.forEach((el) => {
              if (el.id == data.product_id) {
                el.sales -= data.quantity;
              }
            });
          }
        }
        this.attendanceCustomers[index].products.splice(pIndex, 1);
        if (this.bookingForm.repeats && this.bookingForm.repeats.length > 0) {
          this.bookingForm.repeats.forEach((repeat) => {
            let findIndex = repeat.products.findIndex(
              (x) => x.id == data.product_id
            );
            if (findIndex != -1) {
              repeat.products.splice(pIndex, 1);
            }
          });
        }

        if (data.rental == false && this.repeatId) {
          let findIndex = this.currentOrderProducts.findIndex(
            (x) => x.product_id == data.product_id
          );
          if (findIndex != null) {
            this.currentOrderProducts.splice(findIndex, 1);
          }
        }

        this.attendanceCustomers[index].total_price = this.attendanceCustomers[
          index
        ].products.reduce((a, b) => a + parseFloat(b.total_price), 0);
        this.updateAttendanceFormQuantity(index);
        this.refreshCustomersAttendance(index);
      }
      this.checkFullDayProduct();
      this.$forceUpdate();
    },
    loadTimings() {
      let date = this.bookingForm.date;
      this.showLoader('Loading Timings');
      this.$http
          .get(
              `venues/facilities/bookings/schedule?type=ground&venue_service_id=${this.selectedService.id}&date=${date}&venueId=${this.getCurrentVenueId}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let data = response.data.data;
              this.time_increment = data.configuration.time_increment;
              this.min_booking_time = data.configuration.min_booking_time;
              this.enableOvernightBooking = data.configuration.enable_over_night_booking?data.configuration.enable_over_night_booking:false;
              let {start_time, end_time} = this.getTimings(data.facilities);
              let timings = this.generateTimeSlot(start_time, end_time, this.time_increment);
              let formattedTimings = this.convertToTimeSlotObjects(timings);
              if (!formattedTimings.length) {
                this.fs_start_time = null;
                this.fs_end_time = null;
                this.showError('No Facilities available');
                return;
              }
              this.fs_start_time = start_time;
              this.fs_end_time = end_time;
              this.bookingForm.start_timings = formattedTimings.slice(0, formattedTimings.length - 1);
              this.bookingForm.end_timings = formattedTimings.slice(1);
              this.bookingForm.start_time = formattedTimings[0].value;
              this.bookingForm.end_time = moment(formattedTimings[0].value, 'HH:mm:ss').add(this.min_booking_time, 'minutes').format('HH:mm:ss');

              if(this.enableOvernightBooking && this.bookingForm.end_timings){
                let bookingStartTime = moment(this.bookingForm.start_time, "HH:mm:ss")
                let timings2 = this.generateTimeSlotsAdditional(start_time,this.time_increment,this.min_booking_time,bookingStartTime);
                this.bookingForm.end_timings.push(...timings2);
              }
              this.loadFacilities()
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader();
      });
    },
    loadData(){
      if(this.bookingForm.facility){
          this.getRentalProducts();
      }else{
        this.loadFacilities();
      }
    },
    loadFacilities() {
      if (!this.bookingForm.start_time || !this.bookingForm.end_time || !this.bookingForm.date || !this.selectedService) {
        return;
      }
      const startTime = moment(this.bookingForm.start_time, "HH:mm:ss");
      let endTime = moment(this.bookingForm.end_time, "HH:mm:ss");

      let searchEndTime = this.bookingForm.end_time;
      if (this.bookingForm.end_time === '00:00:00') {
        searchEndTime = "23:59:59";
        endTime = moment("23:59:59", "HH:mm:ss");
      }
      if (endTime.isBefore(startTime) && !this.enableOvernightBooking) {
        this.showError('End time cannot be before start time');
        return;
      }
      let duration = endTime.diff(startTime, 'minutes');
      if(duration === 59){
        duration += 1;
      }
      this.showLoader('Loading Facilities');
      this.$http
          .get(
              `venues/shop/facilities?venueId=${this.getCurrentVenueId}&date=${this.bookingForm.date}&start_time=${this.bookingForm.start_time}&end_time=${searchEndTime}&venue_service_id=${this.selectedService.id}&enable_overnight_booking=${this.enableOvernightBooking}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let loadedFacilities = response.data.data;
              this.bookingForm.facilities = loadedFacilities.map((ele) => { 
                  ele.products = [];  return ele; 
                }).filter(f => f.min_booking_time <= duration);
              if(this.fid){
                this.bookingForm.facilities.forEach( (facility) => {
                  if(facility.id === parseInt(this.fid)){
                    this.bookingForm.facility = JSON.parse(JSON.stringify(facility));
                  }
                })
              }
              if (this.bookingForm.facility) {
                this.getRentals()
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader();
      });
    },
    
    changeEndTime() {
      let et = moment(this.bookingForm.start_time, 'HH:mm:ss').add(this.min_booking_time, 'minutes').format('HH:mm:ss');
      if(et === "00:00:00"){
        et = "23:59:59";
      }
      this.bookingForm.end_time = et;
    },
    getDefaultProducts() {
      if (
          this.bookingForm.date &&
          this.bookingForm.start_time &&
          this.bookingForm.facility
      ) {
        let url = `venues/facilities/bookings/rentals?start_time=${this.bookingForm.start_time}&end_time=${this.bookingForm.end_time}&facility_id=${this.bookingForm.facility.id}&date=${this.bookingForm.date}&venue_service_id=${this.selectedService.id}`;
        this.$http
            .get(url)
            .then((response) => {
              this.hideLoader();
              if (response.status == 200 && response.data.status == true) {
                this.bookingForm.facility.products = [];
                let r = response.data;
                delete r.status;
                delete r.message;
                if(this.bookingForm.facility.per_capacity == 0){
                  if(r.data.length > 0){
                    let prod = r.data[0].products;
                    prod = prod.map((p) => {
                        p.total_price = (p.product_price + p.tax_amount) * p.quantity;
                        p.tax = p.tax_amount;
                        p.product_id = p.id;
                        p.total_amount = (p.product_price + p.tax_amount) * p.quantity;
                      return p;
                    })
                    prod.forEach(ele => {
                      let existingProduct = this.bookingForm.facility.products.find(p =>
                          p.product_id === ele.product_id || p.id === ele.id
                      );
                      if (existingProduct) {
                        existingProduct.quantity += ele.quantity;
                        existingProduct.price += ele.price * ele.quantity;
                        existingProduct.total_price += ele.total_price;
                      }
                      else{
                        this.bookingForm.facility.products.push(JSON.parse(JSON.stringify(ele)));
                      }
                    })
                    this.$forceUpdate();
                  }
                }

              } else {
                this.showError("Facility not available for this time");
                this.resetBookingForm();
                this.loadTimings();
              }
            })
            .catch((err) => {
              console.error(err);
              this.hideLoader();
              this.showError("Slot not available");
              this.bookingForm.facility = null;
              this.fid = null;
            });
      } else {
        this.showError("Please select facility,and choose date and times");
      }
    },
    getRentals() {
      if (
          this.bookingForm.date &&
          this.bookingForm.start_time &&
          this.bookingForm.facility
      ) {
        if(this.bookingForm.facility && this.bookingForm.facility.per_capacity){
          this.changeEndTime();
        }
        this.bookingForm.products = [];
        let url = `venues/facilities/bookings/utils?venueId=${this.getCurrentVenueId}&facility_id=${this.bookingForm.facility.id}&booking_id=0&date=${this.bookingForm.date}&start_time=${this.bookingForm.start_time}&increment=${this.time_increment}&min_booking_time=${this.min_booking_time}&per_capacity=${this.bookingForm.facility.per_capacity}&venue_service_id=${this.selectedService.id}&enable_overnight_booking=${this.enableOvernightBooking}`;
        this.showLoader("Checking Availability");
        this.$http
            .get(url)
            .then((response) => {
              this.hideLoader();
              if (response.status == 200 && response.data.status == true) {
                let data = response.data.data;
                this.bookingWithOpenProduct = false;
                this.capacity = 0;
                if (data.is_enable_per_day_capacity && data.capacity && data.total_attendance) {
                  const remainingCapacity = data.capacity - data.total_attendance;
                  this.capacity = remainingCapacity >= 1 ? remainingCapacity : 0;
                } else {
                  this.capacity = data.capacity;
                }

                this.productCombinations = [];
                this.endTimes = data.end_times;

                this.facilityStartTime = data.opening_time;
                this.facilityEndTime = data.closing_time;

                let loadRentalProducts = false;
                if(!this.bookingForm.start_time){
                  this.bookingForm.start_time = data.start_time;
                }
                if(!this.bookingForm.end_time){
                  this.bookingForm.end_time = data.end_time;
                }
                if(this.bookingForm.start_time != data.start_time || this.bookingForm.end_time != data.end_time){
                  loadRentalProducts = true;
                }
               
                let start_time = moment(this.bookingForm.start_time, "HH:mm:ss").format("HH:mm:ss");
                let currentTime = moment(new Date()).format("HH:mm:ss");

                if (moment(start_time, "HH:mm:ss").isBefore(moment(currentTime, "HH:mm:ss"))) {
                  this.pastTime = true;
                } else {
                  this.pastTime = false;
                }
                this.categories = data.categories;
                this.categoriesList = [
                  { name: "All", id: null },
                  { name: "Open Product", id: -1 },
                  ...this.categories,
                ];
                if (data.facility_rentals.length > 0 && this.bookingForm.facility.per_capacity == 0) {
                  let rentalProducts = data.default_products;
                  this.bookingForm.price = 0;
                  this.bookingForm.total_price = 0;
                  this.bookingForm.products = [];
                  rentalProducts.forEach((rentalProduct) => {
                    this.bookingForm.price += rentalProduct.price;
                    this.bookingForm.total_price += rentalProduct.price +
                      (rentalProduct.quantity >= 1
                        ? rentalProduct.total_tax_amount ? rentalProduct.total_tax_amount : rentalProduct.tax_amount *
                          parseFloat(rentalProduct.quantity)
                        : rentalProduct.tax_amount);
                    this.bookingForm.products.push({
                      product_id: rentalProduct.id,
                      product_type_id: rentalProduct.product_type_id,
                      price: rentalProduct.price,
                      name: rentalProduct.name,
                      tax: rentalProduct.tax_amount,
                      total_tax_amount:rentalProduct.total_tax_amount,
                      seasonal_pricing_id:rentalProduct.seasonal_pricing_id,
                      category_id: rentalProduct.category_id,
                      rental: true,
                      product_price: rentalProduct.product_price_when_overlapping
                        ? rentalProduct.product_price_when_overlapping
                        : rentalProduct.product_price,
                      quantity: rentalProduct.quantity,
                      total_price:
                        rentalProduct.price +
                        (rentalProduct.quantity >= 1
                          ?
                            rentalProduct.total_tax_amount ? rentalProduct.total_tax_amount :
                            rentalProduct.tax_amount *
                            parseFloat(rentalProduct.quantity)
                          : rentalProduct.tax_amount),
                    });

                  });
                } else {
                  if(this.bookingForm.facility.per_capacity == 1){
                    this.$http
                      .get(
                        `venues/facilities/bookings/participants?facility_id=${this.bookingForm.facility.id}&start_time=${this.bookingForm.start_time}&end_time=${this.bookingForm.end_time}&date=${this.bookingForm.date}`
                      )
                      .then((participans_response) => {
                        this.hideLoader();
                        if (
                          participans_response.status == 200 &&
                          participans_response.data.status == true
                        ) {
                          const data_participans_response = participans_response.data.data;
                          this.totalParticipants = data_participans_response.reduce( (a, b) => a + parseFloat(b.attendance),0);
                          this.bookedCapacity += this.totalParticipants;
                          if (this.bookedCapacity >= this.capacity) {
                            this.attendanceSwitch = false;
                          } else {
                            this.attendanceSwitch = true;
                          }
                          this.editFlag = false;
                        }
                      });
                    this.bookingForm.products = [];
                    this.bookingForm.price = 0;
                    this.bookingForm.total_price = 0;
                  }
                }
                if (this.bookingForm.facility.per_capacity == 1) {
                  if (data.facility_rentals[0]) {
                    this.categoriesList.push({
                      name: "Tickets",
                      id: data.facility_rentals[0].category_id,
                    });
                  }
                }
                if (data.facility_rentals.length) {
                  if (data.facility_rentals[0]) {
                    let rentalProducts = {
                      id: data.facility_rentals[0].category_id,
                      name: this.bookingForm.facility.per_capacity == 1 ? "Tickets" : "Rentals",
                      products: [
                        ...data.facility_rentals.map((item) => {
                          item.id = item.product_id;
                          item.rental = true;
                          return item;
                        }),
                      ],
                    };
                    this.categories.push(rentalProducts);
                  }
                }
                if(loadRentalProducts){
                  this.getRentalProducts();
                }
                this.bookingForm.opt_marketing = false;
                this.refreshComponent = now();
                if (this.$refs && this.$refs.form) {
                  this.$refs.form.resetValidation();
                }
                this.hideLoader();
                this.$forceUpdate();
              } else {
                this.fid = null;
                this.showError("Facility not available for this time");
                this.resetBookingForm();
              }
            })
            .catch((err) => {
              this.fid = null;
              console.error(err);
              this.hideLoader();
              this.showError("Slot not available");
              // this.resetBookingForm();
              if(this.selectedService) {
                this.changeService(this.selectedService.id);
              }
            });
      } else {
        this.showError("Please select facility,and choose date and times");
      }
    },
    getRepeatSuffix(type) {
      if (type.toString().toLowerCase() === 'daily') {
        return 'Day(s)'
      } else if (type.toString().toLowerCase() === 'weekly') {
        return 'Week(s)'
      } else if (type.toString().toLowerCase() === 'monthly') {
        return 'Month(s)'
      }
    },
    timeFormat(time) {
      if (!time) {
        return time;
      }
      return moment(time, "HH:mm:ss").format("hh:mm a");
    },
    getStartTimes() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.bookingForm.start_timings = [];
      this.bookingForm.start_timings.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(60, "minutes");
        currentTime = currentTime.add(60, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.bookingForm.start_timings.push(data);
          hour = 24;
        } else {
          this.bookingForm.start_timings.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
    addBookingTiming() {
      this.bookingForm.push({
        date: null,
        start_time: null,
        end_time: null,
        facility: null,
        repeatType: 'Weekly',
        weekdays: [],
        repeat: 1,
        repeatEndType: 'Count',
        endAfter: 1,
        productCategoryId: null,
        selectedProduct: {
          title: '',
          quantity: 1,
          tax_type_id: null,
          pre_tax_price: 0,
          total_price: 0,
          rental: true,
        }
      })
    },
    removeBookingTime(index) {
      this.bookingForm.bookings.splice(index, 1)
    },
    async getFacilityTimings() {
      let timings = { start_time:null, end_time:null};
      this.showLoader("Loading");
      await this.$http
          .get(`venues/facilities/get-min-time?facility_id=${this.bookingForm.facility.id}&date=${this.bookingForm.date}&same_day=1`)
          .then((response) => {
            if (response.status === 200 && response.data.status === true) {
              timings.start_time = response.data.start_time;
              timings.end_time = response.data.end_time;
              this.hideLoader();

            }
          })
          .catch((error) => {
            this.hideLoader();
            console.error(error);
            // this.errorChecker(error);
          });
      return timings;
    },
    async addOverNightBookingTimes(data){
      let times = await this.getFacilityTimings();
      let minStartTime = null;

      if(times && times.end_time) {
        minStartTime = times.start_time.start_time
      }else{
        minStartTime = data.facility_rentals.reduce((min, obj) => {
          const start_time = moment(obj.start_time, 'HH:mm:ss');
          return start_time.isAfter(moment(min, 'HH:mm:ss')) ? start_time.format('HH:mm:ss') : min;
        }, moment('00:00:00', 'HH:mm:ss'));
      }

      let newTimesots = this.generateTimeSlotsAdditional(minStartTime,this.time_increment,this.min_booking_time,this.bookingForm.start_time);
      this.bookingForm.end_timings.push(...newTimesots);
    },
    // Repeat booking code
    changeRepeatBookingSwitch() {
      if (!this.bookingForm.repeat) {
        this.currentRepeatDates = null;
        this.getRentalProducts();
        // this.getRentals();
        this.bookingForm.repeats = [];
      } else {
        this.$store.commit("resetRepeatState");
        this.$store
          .dispatch("addRepeatRow", {
            start_time: this.bookingForm.start_time,
            end_time: this.bookingForm.end_time,
            date: this.bookingForm.date,
            facility_id: this.bookingForm.facility.id,
            init: true,
          })
          .catch((error) => {
            this.errorChecker(error);
          });
        this.bookingForm.repeats = [];
      }
      this.$forceUpdate();
    },
    getEndTimingsObject(){
      return this.bookingForm.end_timings;
    },
    changeStartTime(){
      if(!this.enableOvernightBooking){
          return;
      }
      if(this.fs_start_time && this.fs_end_time){
        const start_time = moment(this.fs_start_time, "HH:mm:ss");
        let endTime = start_time.add(this.min_booking_time, "minutes");
        this.bookingForm.end_time = moment(endTime, "HH:mm:ss");
        let bookingStartTime = moment(this.bookingForm.start_time, "HH:mm:ss")
        if(this.enableOvernightBooking && this.bookingForm.end_timings && this.bookingForm.start_time){
          let ovTimings = this.generateTimeSlotsAdditional(this.fs_start_time,this.time_increment,this.min_booking_time,bookingStartTime);
          if(ovTimings && ovTimings.length > 0){
            let oldTimings = this.generateTimeSlot(this.fs_start_time,this.fs_end_time,this.time_increment);
            let formattedTimings = this.convertToTimeSlotObjects(oldTimings);
            // Use moment.js to filter out past times
            formattedTimings = formattedTimings.filter((time) => {
              return moment(time.value, 'HH:mm:ss').isSameOrAfter(bookingStartTime);
            });
            formattedTimings.splice(0, 1);
            this.bookingForm.end_timings = [];
            if (!formattedTimings.length) {
              this.fs_start_time = null;
              this.fs_end_time = null;
              this.showError('No Facilities available');
              return;
            }
            formattedTimings.push(...ovTimings);
            this.bookingForm.end_timings = formattedTimings;
            this.$forceUpdate();
          }
        }
      }
    },
    getFacilityEndTimes() {
      this.showLoader("Loading");
      this.bookingForm.end_timings = [];
      this.$http.get(
          `venues/facilities/bookings/utils?facility_id=${this.bookingForm.facility_id}&date=${this.bookingForm.date}&start_time=${this.bookingForm.start_time}&increment=${this.time_increment}&min_booking_time=${this.min_booking_time}&booking_id=0&per_capacity=${this.bookingForm.facility.per_capacity}&venue_service_id=${this.selectedService.id}&enable_overnight_booking=${this.enableOvernightBooking}`
        
        ).then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.bookingForm.end_timings = response.data.data.end_times;
          }
        });
    },
    getRentalProducts() {
      if (this.currentRepeatDates) {
        this.validateRepeatBookings(this.currentRepeatDates);
      } else {
        this.$http
          .get(
            `venues/facilities/bookings/rentals?start_time=${this.bookingForm.start_time}&end_time=${this.bookingForm.end_time}&facility_id=${this.bookingForm.facility.id}&date=${this.bookingForm.date}&venue_service_id=${this.selectedService.id}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.productCombinations = data;
              if (data.length) {
                this.changeRentalProducts(data[0]);
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },
    changeRentalProducts(rentalCombination) {
      let otherProducts = [];
      if (!this.repeatId && this.bookingForm.products) {
        otherProducts = this.bookingForm.products.filter(
          (item) => !item.rental
        );
      }
      this.selectedCombinationPrice = rentalCombination.key;
      let rentals = [];
      rentalCombination.products.forEach((product) => {
        let tax_amount = product.total_tax_amount? product.total_tax_amount: product.tax_amount;
        if(product.is_repeatable){
            if(!product.product_price_when_overlapping){
              tax_amount = tax_amount * parseFloat(product.quantity < 1?1:product.quantity);
            }
            if(product.product_price_when_overlapping && product.price > product.product_price_when_overlapping){
              tax_amount = tax_amount * parseFloat(product.quantity < 1?1:product.quantity);
            }
        }
        rentals.push({
          product_id: product.id,
          product_type_id: product.product_type_id,
          price: product.price,
          name: product.name,
          tax: product.tax_amount,
          category_id: product.category_id,
          rental: true,
          product_price: product.product_price_when_overlapping? product.product_price_when_overlapping: product.product_price,
          quantity: product.quantity,
          total_price: product.price + tax_amount,
          total_tax_amount: product.total_tax_amount? product.total_tax_amount: tax_amount,
        });
      });
      if (otherProducts) {
        this.bookingForm.products = [...rentals, ...otherProducts];
      } else {
        this.bookingForm.products = rentals;
      }

      this.bookingForm.total_price = this.bookingForm.products.reduce(
        (a, b) => a + parseFloat(b.total_price),
        0
      );
      this.showCombinationDialog = false;
      this.$forceUpdate();
    },
    setRepeatData() {
      this.productCombinations = [];
      this.bookingForm.repeats = this.$store.getters.getRepeats;
      this.combineRepeatProducts();
    },
    combineRepeatProducts() {
      let otherProducts = this.bookingForm.products.filter(
        (item) => !item.rental
      );
      let rentals = [];
      this.bookingForm.repeats.forEach((repeat) => {
        repeat.products.forEach((product) => {
          let index = rentals.findIndex(
            (item) => item.product_id == product.id
          );
          if (index == -1) {

            let tax_amount =  product.product_price_when_overlapping?product.tax_amount:product.total_tax_amount ? product.total_tax_amount : (product.tax_amount * (product.quantity < 1 ? 1 : product.quantity));
            if(product.product_price_when_overlapping){
              if(product.price >= product.product_price_when_overlapping){
                tax_amount = (product.product_price_when_overlapping * 5 /100) * parseFloat(product.quantity < 1 ? 1 : product.quantity);
              }
            }

            rentals.push({
              product_id: product.id,
              product_type_id: product.product_type_id,
              price: product.price,
              name: product.name,
              tax: product.tax_amount,
              category_id: product.category_id,
              rental: true,
              product_price: product.product_price_when_overlapping? product.product_price_when_overlapping: product.product_price,
              quantity: product.quantity,
              total_price: product.price + tax_amount,
              total_tax_amount: product.price + product.total_tax_amount,

            });
          } else {

            rentals[index].price += product.price;
            rentals[index].quantity += product.quantity;
            rentals[index].total_price += product.price + (product.total_tax_amount ? product.total_tax_amount : product.tax_amount);
            rentals[index].total_tax_amount += product.price + product.total_tax_amount;

          }
        });
      });

      if (otherProducts) {
        this.bookingForm.products = [...rentals, ...otherProducts];
      } else {
        this.bookingForm.products = rentals;
      }
      this.bookingForm.price = this.bookingForm.products.reduce(
        (a, b) => a + parseFloat(b.price),
        0
      );
      this.bookingForm.total_price= this.bookingForm.products.reduce(
        (a, b) => a + parseFloat(b.total_price),
        0
      );
      this.$forceUpdate();
    },
    async removeRepeatRow(index) {
      await this.$store
        .dispatch("removeRepeatRow", index)
        .then(() => {
          this.setRepeatData();
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addRepeatRow() {
      this.$store
        .dispatch("addRepeatRow", {
          start_time: null,
          end_time: null,
          date: this.bookingForm.date,
          facility_id: this.bookingForm.facility.id,
          init: true,
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setCurrentOrderProducts(data) {
      this.currentOrderProducts = data;
      this.$forceUpdate();
    },
    setCustomerProduct(index = null, products) {
      if (index === null) {
        this.bookingForm.products = products;
        this.checkFullDayProduct();

        this.bookingForm.total_price = products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
        this.refreshComponent++;
        this.updateBookingFormQuantity();
      } else {
        this.attendanceCustomers[index].products = products;
        this.attendanceCustomers[index].total_price = products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
        this.updateAttendanceFormQuantity(index);
      }

      this.$forceUpdate();
    },
    checkFullDayProduct() {
      const hasFullDay = this.bookingForm.products.some(
        (obj) => obj.is_full_day === 1
      );

      if (hasFullDay) {
        this.fullDay = true;
      } else {
        this.fullDay = false;
      }
    },
    updateBookingFormQuantity() {
      let old = this.bookingFormTotal;
      let max = this.bookingForm.products.reduce(
        (acc, num) =>
          acc +
          (num.rental == true
            ? parseInt(num.quantity) * parseInt(num.participant_count)
            : 0),
        0
      );
      if (old > max && max < this.bookingFormAdded) {
        this.bookingFormAdded = max + 1;
      }

      this.bookingFormTotal = max;
    },
    chooseRentalCombination() {
      this.showCombinationDialog = true;
    },
    deleteProduct(data) {
      this.deletedProducts = data;
    },
    isFacilityTimingAlreadyInCart(){
      let bookingExists = false;
      const cartItems = this.$store.getters.getCartItems;
      if(cartItems && cartItems.length) {
        cartItems.forEach((item) => {
          if(item.type === "Facility" && item.facility_id && item.facility_id === this.bookingForm.facility.id && this.bookingForm.facility.per_capacity == 0) {
            let itemStartDateTime = new Date(item.start_date + ' ' + item.start_time);
            let itemEndDateTime = new Date(item.start_date + ' ' + item.end_time);
            let bookingFormStartDateTime = new Date(this.bookingForm.date + ' ' + this.bookingForm.start_time);
            let bookingFormEndDateTime = new Date(this.bookingForm.date + ' ' + this.bookingForm.end_time);
            // Check if there is an overlap
            if (bookingFormStartDateTime < itemEndDateTime && bookingFormEndDateTime > itemStartDateTime) {
                bookingExists = true;
            }
        }
        });
      }
      return bookingExists;
    },
    loadFacilityPromotions(){
      this.$store.dispatch("loadShopPromotions", {
        date: this.bookingForm.date,
        venue_service_id: this.selectedService.id,
        product_type: "Facility",
        venueId: this.getCurrentVenueId
      });
    },
    checkProductOffers(){
      this.offers = [];
      this.$store.dispatch('searchOffer').then( (response) => {
        if (response && response.status == 200 && response.data.status == true) {
          const offerPackages =response.data.data || null;
          if(offerPackages && offerPackages.length > 0) {
            this.offers = offerPackages;
            this.showOfferPopup = true;
          }else{
            this.offers = [];
          }
        }
      });
    },
    closeOfferPopup(){
      this.showOfferPopup = false;
      this.offers = [];
    },
    resetAll(){
        this.enableOvernightBooking = false;
        this.valid = false;
        this.showCustomerPopup = false;
        this.time_increment = 60;
        this.min_booking_time = 60;
        this.facilities = [];
        this.selectedService = null;
        this.fs_start_time = null;
        this.fs_end_time = null;
        this.bookingForm = {
          facilities: [],
          date: moment().format("YYYY-MM-DD"),
          start_time: null,
          end_time: null,
          facility: null,
          repeatType: 'Weekly',
          weekdays: [],
          products: [],
          repeatEndType: 'Count',
          endAfter: 1,
          productCategoryId: null,
          selectedProduct: {
            title: '',
            quantity: 1,
            tax_type_id: null,
            pre_tax_price: 0,
            total_price: 0,
            rental: true,
          },
          start_timings: [],
          end_timings: [],
          timings: [],
          repeat: false,
          attendance: false,
          attendance_count: 1,
          opt_marketing: false,
          price: 0,
          discount: null,
          promotion_code: null,
          total_price: 0,
        }
        this.repeatId = null;
        this.currentRepeatDates = null;
        this.deletedProducts = [];
        this.productCombinations = [];
        this.showCombinationDialog = false;
        this.selectedCombinationPrice = 0;
        this.currentOrderProducts = [];
        this.categories = [];
        this.categoriesList = [
          {name: "All", id: null},
          {name: "Open Product", id: -1},
        ]
        this.refreshComponent = 0;
        this.fullDay =  false;
        this.bookingFormTotal =  0;
        this.bookingFormAdded =  1;
        this.totalParticipants =  0;
        this.bookedCapacity =  1;
        this.attendanceSwitch =  false;
        this.isEmiratesIdCheck = false;
        this.offers =  [];
        this.showOfferPopup =  false;
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .search {
    border-radius: 4px;
    border: 1px solid #EFEDED;
    background: #FFF;
    max-width: 250px;

    .v-label {
      opacity: 0.3;
    }
  }

  .v-input__prepend-inner {
    margin-top: 12px !important;
  }

  .v-input {
    font-size: 0.875rem !important;
  }
}

.service {
  border-radius: 4px;
  border: 1px solid rgba(17, 42, 70, 0.10);
  background: #FFF !important;
  padding: 0.875rem 1.25rem;
  cursor: pointer;
  text-transform: capitalize;
  opacity: 0.7 !important;
  font-weight: 400 !important;

  &.active {
    font-weight: 500 !important;
    border-radius: 4px;
    border: 1px solid #4FAEAF;
    color: #4FAEAF;
    opacity: 1 !important;
    background: rgba(79, 174, 175, 0.10) !important;
  }
}

.product-details {
  background: #F7F9FB;
}

.added-product-chip {
  padding: 1rem 0.75rem !important;
  line-height: 1rem !important;

  &, &:hover {
    background: #F5F8FA !important;
  }

  &.v-size--default {
    height: fit-content !important;
  }
}

.open-product {
  cursor: pointer;
  background: #F5F8FA !important;
  padding: 0.25rem;
  text-align: center;
}
</style>